<!-- 解决方案 -->
<template>
    <div class="solution">
        <header class="header">
            <div class="header-info">
                <h2>环卫一体化解决方案</h2>
                <p>
                    综合利用物联网、云计算等技术，对环卫管理所涉及到的人、车、物、事进行全过程实时管理，提升环卫作业质量，降低环卫运营成本；对城市环卫设计规划及环卫管理模式的合理性进行数字化的评估；通过数字评估垃圾分类实施效果，有效提升垃圾减量化、无害化、资源化程度。
                </p>
                <div class="info-btn">
                  <span><a href="#applyContact">预约试用</a></span>
                  <span @click="qrCode">联系我们</span>
                </div>
            </div>
            <div class="header-img">
                <img v-lazy="require('../../assets/solutions/banner-pc.png')" alt="" />
            </div>
        </header>
        <!-- 功能跳转开始 -->
        <div class="feature-list">
            <ul>
                <li v-for="(item, index) in featureList" :key="index" @click="featureListClick(index)">
                    <a :href="item.id" :class="item.isActive ? 'isActive' : ''">{{ item.name }}</a>
                </li>
            </ul>
        </div>
        <!-- 功能跳转结束 -->
        <!-- 块内容开始 -->
        <div class="content">
            <!-- 行业痛点 -->
            <section class="list1">
                <a name="list1" style="position: relative; top: -4.5rem"></a>
                <h2>行业痛点</h2>
                <div class="container">
                    <div class="list" v-for="(item, index) in list1" :key="index">
                        <div class="circle">{{ item.title }}</div>
                        <div class="details">
                            <p v-for="(item1, index1) in item.details" :key="index1">{{ item1 }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 方案架构 -->
            <section class="list2">
                <a name="list2" style="position: relative; top: -4.5rem"></a>
                <h2>方案架构</h2>
                <div class="container">
                    <p>
                        环卫一体化解决方案，将实现环卫管理的智能化、精细化、规范化、便捷化、实时化和高效化，使环卫管理过程中出现的问题能够及早发现、及时解决，形成沟通快捷、分工明确、责任到位、反应快速、处置及时、运转高效的城市管理长效机制。
                    </p>
                    <div class="list"></div>
                </div>
            </section>
            <!-- 方案优势 -->
            <section class="list3">
                <a name="list3" style="position: relative; top: -4.5rem"></a>
                <h2>方案优势</h2>
                <div class="container">
                    <div class="list" v-for="(item, index) in list3" :key="index">
                        <img v-lazy="item.img" alt="" />
                        <div class="info">
                            <h4>{{ item.title }}</h4>
                            <span></span>
                            <p>{{ item.details }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 经典案例 -->
            <section class="list4">
                <a name="list4" style="position: relative; top: -4.5rem"></a>
                <h2>经典案例</h2>
                <div class="container">
                    <div class="list" v-for="(item, index) in list4" :key="index">
                        <div class="title">
                            <img src="../../assets/solutions/Vector.png" alt="" />
                            <h4>{{ item.title }}</h4>
                        </div>
                        <p>{{ item.details }}</p>
                    </div>
                </div>
            </section>
            <!-- 更多解决方案 -->
            <section class="list5">
                <a name="list5" style="position: relative; top: -4.5rem"></a>
                <h2>更多解决方案</h2>
                <div class="container">
                    <template>
                    	<router-link v-for="(item, index) in list5" :key="index" :to="{path: item.path}">
                    		<div class="list">
                    			<h4>{{ item.title }}</h4>
                    			<p>{{ item.details }}</p>
                    			<span>了解详情</span>
                    		</div>
                    	</router-link>
                    </template>
                </div>
            </section>
        </div>
        <!-- 块内容结束 -->
        <!-- 二维码 -->
        <contactUs ref="contactUs"></contactUs>
        <!-- 移动端二维码 -->
				<apply ref="apply"></apply>
    </div>
</template>

<script>
import apply from "@/components/apply/apply";
import contactUs from "@/components/contactUs/contactUs";
import { myMixins } from "@/utils/mixin/mixin.js";
export default {
    mixins: [myMixins],
    components: { apply, contactUs },
    data() {
        return {
            id: 2, //标识 区分哪一个解决方案，来显示动态显示更多解决方案
            featureList: [
                { name: "行业痛点", isActive: true, id: "#list1" },
                { name: "方案架构", isActive: false, id: "#list2" },
                { name: "方案优势", isActive: false, id: "#list3" },
                { name: "经典案例", isActive: false, id: "#list4" },
                { name: "更多解决方案", isActive: false, id: "#list5" },
            ], //功能列表跳转

            list1: [
                {
                    title: "人员管理难",
                    details: ["作业标准", "人员排班", "环卫事件", "出勤管理"],
                },
                {
                    title: "车辆管理难",
                    details: ["路线规划", "维修保养", "油耗管理", "出勤排班"],
                },
                {
                    title: "设备管理难",
                    details: ["资产盘点", "称重计量", "满溢检测", "重要点位"],
                },
                {
                    title: "事件管理难",
                    details: ["事件管理", "抽查巡检", "车务管理", "数据赋能"],
                },
            ], //行业痛点
            list3: [
                {
                    title: "环卫作业全流程管理",
                    img: require("../../assets/solutions/pc-s3-1.png"),
                    details: "建立人车物事全链数字化管理\n适用于环卫一体化的基本数据采集体系\n固化作业流程",
                },
                {
                    title: "高效质量督查",
                    img: require("../../assets/solutions/pc-s3-2.png"),
                    details: "完善绩效管理，提高服务质量\n坚持过程管理与结果管理相结合\n既做好痕迹管理又避免留痕主义",
                },
                {
                    title: "垃圾处置全链监督",
                    img: require("../../assets/solutions/pc-s3-3.png"),
                    details: "从分类劝导员到垃圾收转运清运线路精细化管理\n全时段、全方位、精准高效的全生命周期",
                },
                {
                    title: "可视化数据分析研判",
                    img: require("../../assets/solutions/pc-s3-4.png"),
                    details: "数字驱动管理组织升级\n可视化调度与可视化分析相结合\n为点位分布、路线优化提供数据支撑 ",
                },
            ], //方案优势
            list4: [
                {
                    title: "上海财经大学千村调研2.0系统",
                    details:
                        "“千村调查”项目是上海财经大学“211工程”三期创新人才培养项目，已成功实施十三期。“走千村，访万户，读中国”。“千村调查”项目是以 ...",
                },
                {
                    title: "保利物业百优行动品检项目",
                    details:
                        "“百优行动”是保利物业提升服务品质的专项行动，以物业管理基础服务触点动作为契机，采用场景式宣传，标准化服务为切入点，来加强客户感知…",
                },
                {
                    title: "西塘全域化公共服务项目",
                    details:
                        "2020年9月26日，保利物业正式进驻西塘全域。本次服务升级，旨在将“政府主导、企业协同、服务对象积极参与”的三位一体全域化服务，打造出…",
                },
            ], //经典案例
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        // this.distinguish();
    },
    methods: {
        // 功能列表跳转
        featureListClick(e) {
            this.featureList.map((item, index) => {
                item.isActive = e === index ? true : false;
            });
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "solution_PC3.scss";
}
@media screen and (max-width: 750px) {
    @import "solution_mobile3.scss";
}
</style>
